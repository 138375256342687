import './EventForm.css';
import { Button, Form, InputNumber, Checkbox } from 'antd';
import useFetchNavigate from './useFetchNavigate';
import useFormSubmission from './useFormSubmission';
import { useParams } from "react-router-dom";
import { useMemo } from 'react';

export default function OpenMicBookingForm() {
  const { id } = useParams();
  useFetchNavigate();
  const {
    sending,
    submitForm,
    spinner
} = useFormSubmission('MicBookingForm');

  const { dateMap, openMics } = useMemo(() => {
    const localItem = localStorage.getItem(id);
    if(localItem) {
      const { payload } = JSON.parse(localItem);
      const { dateMap, openMics } = payload;
      return { 
        dateMap: new Map(dateMap), 
        openMics
      };
    }
    return { dateMap: new Map(), openMics: [] };  
  }, [id]);
  const getDateInfo = ({ dayOfWeek, startTime, endTime, number }) => {
    const date = dateMap.get(`${dayOfWeek}-${number}`);
    return {
        key: `${number}-${dayOfWeek}-${startTime.replaceAll(" ", "_")}-${endTime.replaceAll(" ", "_")}`,
        label: date ? `${dayOfWeek} ${date} ${startTime} ${endTime}`: `${dayOfWeek} ${startTime} ${endTime}`
    }
  }
  if (sending) {
    return spinner;
  }
  return (
    <div className="Contianer">
        <h2>
            Flophouse Open Mic Hosting
        </h2>
        <Form
            name="basic"
            labelCol={{
                span: 12,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={submitForm}
            autoComplete="off"
        >
            <Form.Item
                label="Number of shows"
                name="numberOfShows"
                help="How many shows do you want to host this 2 week period?"
                rules={[
                    {
                    required: true,
                    message: 'If you cannot do any put 0',
                    },
                ]}
            >
                <InputNumber min={0} max={openMics.length * 2} />
            </Form.Item>
            {openMics.map((event) => {
                const { label, key } = getDateInfo({...event, number: 1});
                return (<Form.Item 
                    key={key}
                    label={label}
                    name={key}
                    valuePropName="checked"
                >
                    <Checkbox/>
                </Form.Item>)
            })}
            {openMics.map((event) => {
                const { label, key } = getDateInfo({...event, number: 2});
                return (<Form.Item 
                    key={key}
                    label={label}
                    name={key}
                    valuePropName="checked"
                >
                    <Checkbox/>
                </Form.Item>)
            })}
            <Button type="primary" htmlType="submit" style={{float: 'right'}}>
                Submit
            </Button>
        </Form>
    </div>
  );
}
