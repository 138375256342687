import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import MissingForm from './MissingForm';
import CreateForm from './CreateForm';
import OpenMicForm from './OpenMicForm';
import BarkingForm from './BarkingForm';
import OpenMicBookingForm from "./OpenMicBookingForm";
import ProducedShowForm from './ProducedShowForm'
import BarkingBookingForm from './BarkingBookingForm';
import FormLoader from './FormLoader';
import EventForm from './EventForm';
import SubmittedForm from './SubmittedForm';
import ConfirmForm from './ConfirmForm';
import FailedForm from './FailedForm';
import FailedToLoad from './FailedToLoad';
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MissingForm/>
    ),
  },
  {
    path: ":id",
    element: (
      <FormLoader />
    ),
  },
  {
    path:"MicForm/:id",
    element: (<OpenMicForm/>)
  },
  {
    path:"BarkForm/:id",
    element: (<BarkingForm/>)
  },
  {
    path:"MicBookingForm/:id",
    element: (<OpenMicBookingForm/>)
  },
  {
    path: "BarkingBookingForm/:id",
    element: (<BarkingBookingForm/>)
  },
  {
    path:"CreateForm/:id",
    element: (<CreateForm/>)
  },
  {
    path:"FailedForm",
    element: (<FailedForm/>)
  },
  {
    path:"FailedToLoad",
    element: (<FailedToLoad/>)
  },
  {
    path:"ConfirmForm/:id",
    element: (<ConfirmForm/>)
  },
  {
    path:"SubmittedForm/:type",
    element: (<SubmittedForm/>)
  },
  {
    path:"EventForm/:id",
    element: (<EventForm/>)
  },
  {
    path:"ProducedShowForm/:id",
    element: (<ProducedShowForm/>)
  },
  {
    path:"MissingForm/:id",
    element: (<MissingForm/>)
  }
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
reportWebVitals();
