import './EventForm.css';
import { Button, Form, InputNumber, Checkbox } from 'antd';
import useFetchNavigate from './useFetchNavigate';
import useFormSubmission from './useFormSubmission';
import { useParams } from "react-router-dom";
import { useMemo } from 'react';

function toEstString(time) {
    return new Date(time).toLocaleString("en-US", {timeZone: 'America/New_York'});
}
  

export default function BarkingBookingForm() {
  const { id } = useParams();
  useFetchNavigate();
  const {
    sending,
    submitForm,
    spinner
} = useFormSubmission('BarkingBookingForm');

  const events = useMemo(() => {
    const localItem = localStorage.getItem(id);
    if(localItem) {
      const { payload } = JSON.parse(localItem);
      return payload.events;
    }
    return [];  
  }, [id]);
  const getDateInfo = ({ startTime, endTime }) => {
    const [dateStart, timeStart] = toEstString(startTime).split(", ");
    const [_dateEnd, timeEnd] = toEstString(endTime).split(", ");
    return {
        key: `${startTime}-${endTime}`,
        label: `${dateStart} ${timeStart} ${timeEnd}`
    }
  }
  if (sending) {
    return spinner;
  }
  return (
    <div className="Contianer">
        <h2>
            Flophouse Barking
        </h2>
        <Form
            name="basic"
            labelCol={{
                span: 12,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={submitForm}
            autoComplete="off"
        >
            <Form.Item
                label="Number of shows"
                name="numberOfShows"
                help="How many shows do you want to bark this two week period?"
                rules={[
                    {
                    required: true,
                    message: 'If you cannot do any put 0',
                    },
                ]}
            >
                <InputNumber min={0} max={events.length} />
            </Form.Item>
            {events.map(event => {
                const { label, key } = getDateInfo(event);
                return (<Form.Item 
                    key={key}
                    label={label}
                    name={key}
                    valuePropName="checked"
                >
                    <Checkbox/>
                </Form.Item>)
            })}
            <Button type="primary" htmlType="submit" style={{float: 'right'}}>
                Submit
            </Button>
        </Form>
    </div>
  );
}
