import './EventForm.css';
import { Button, Form, Input } from 'antd';
import useFetchNavigate from './useFetchNavigate';
import useFormSubmission from './useFormSubmission';

export default function OpenMicForm() {
  useFetchNavigate();
  const {
    sending,
    submitForm,
    spinner
} = useFormSubmission('MicForm');
  if (sending) {
    return spinner;
  }
  return (
    <div className="Contianer">
        <h2>
            Flophouse Onboarding Open Mic Host
        </h2>
        <Form
            name="basic"
            labelCol={{
            span: 9,
            }}
            wrapperCol={{
            span: 16,
            }}
            style={{
            maxWidth: 600,
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={submitForm}
            autoComplete="off"
        >
            <Form.Item
                label="Full Name"
                name="name"
                rules={[
                    {
                    required: true,
                    message: 'Please add your full name',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Button type="primary" htmlType="submit" style={{float: 'right'}}>
                Submit
            </Button>
        </Form>
    </div>
  );
}
